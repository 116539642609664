<template>
  <div>
    <v-menu
      offset-x
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-tune-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in getCardOptions"
          :key="index"
        >
          <v-list-item-title>
            <v-checkbox
              v-model="item.status"
              value="on"
              :label="item.card_name"
              @change="onChangeCardStatus(item)"
            />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    tenantTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      selectedCards: "dashboard/getSelectedCards",
      getCardOptions: "dashboard/getCardOptions",
    }),
  },
  methods: {
    async onChangeCardStatus(item) {
      const params = {};
      params.card_id = item.id;
      params.user_card_id = item.card_id;
      params.status = item.status || "off";
      params.section = item.section;
      params.card_color_code = item.card_color_code;
      params.sort_order = item.sort_order;
      params.vehicle_tech = item.vehicle_tech;
      params.tenant_tech = this.tenantTech;
      await this.$store.dispatch("dashboard/updateCard", params);
    },
  },
};
</script>
<style lang="sass" scoped>
.v-menu__content
  height: 300px
</style>
